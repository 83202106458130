import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
// import CategoryList from '../components/CategoryList'

export const getAllCategories = (products) => {
  const res = new Set()
  products.map((p) => {
    res.add(p.category[0])
  })

  return [...res]
}

const Products = (props) => {
  let products = get(props, 'data.allContentfulProduct.nodes')
  const categories = getAllCategories(products)
  // const [active, setActive] = useState(products[0].category)

  return (
    <Layout location={props.location}>
      <Seo title="Electronics Products | Ronak Electronics" />

      <Container>
        {/* <CategoryList {...{ products, active, setActive }} /> */}

        <List>
          {categories.map((c, i) => (
            <li key={i}>
              <h2>{c}</h2>
              <ImageAndProducts>
                <div className="image">
                  <GatsbyImage
                    alt={products.filter((p) => p.category[0] === c)[0].name}
                    image={
                      products.filter((p) => p.category[0] === c)[0]
                        .featureImage.gatsbyImageData
                    }
                  />
                </div>
                <div className="products">
                  {products
                    .filter((p) => p.category[0] === c)
                    .map((p) => (
                      <Link key={p.id} to={`/product/${p.name}`}>
                        <p>{p.name}</p>
                      </Link>
                    ))}
                </div>
              </ImageAndProducts>
            </li>
          ))}
        </List>
      </Container>
    </Layout>
  )
}

export default Products

export const pageQuery = graphql`
  query ProductIndexQuery {
    allContentfulProduct {
      nodes {
        id
        name
        category
        description
        body {
          raw
        }
        featureImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1000)
          resize {
            src
          }
        }
      }
    }
  }
`

const Container = styled.div`
  padding: 50px 0;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1200px) {
    padding: 30px 15px;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  h2 {
    border-bottom: 3px solid ${({ theme }) => theme.color.p};
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ImageAndProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  .products {
    p {
      border-bottom: 1px dashed rgb(0 0 0 / 30%);
      transition: 0.1s;
      border-radius: 4px;
      &:hover {
        background: black;
        color: white;
        padding-left: 5px;
      }
    }
  }
`
